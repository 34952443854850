let url;
let urlb;
let socketurl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	url = 'http://localhost:8000/api';
	urlb = 'http://localhost:8000';
	socketurl = 'http://localhost:4001';
} else {
	url = 'https://api.adm.kelola.biz/api';
	urlb = 'https://api.adm.kelola.biz';
	socketurl = 'http://192.168.11.144:4001';
}

const appName = 'Dharma';
const customerStyles = {
	control: (styles, {isFocused}) => ({
		...styles,
		backgroundColor: 'transparent',
		boxShadow: isFocused ? '0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06)' : 'none'
	}),
};
export { url, appName, customerStyles, urlb, socketurl }
